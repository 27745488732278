export default {
  Login: "Login",
  ForgotPassword: "Forgot Password?",
  EnterPassword: "Enter Password",
  EnterEmail: "Enter Email",
  DontHaveAccount: `Don't have an account?`,
  Create: "Register",
  FirstName: "Enter First Name",
  LastName: "Enter Last Name",
  PassDesc: `1 upper case character, 1 lower case character, 1 digit and 1 special character other than underscore, It must contain at least 8 characters and no more than 32 characters.`,
  ConfirmPassword: "Enter Confirm Password",
  ContactNumber: "Enter Contact Number",
  SecretQuestion: "Secret Question",
  EnterAnswer: "Enter Answer",
  Address: "Address",
  ByClick: "By clicking Sign Up, you agree to our",
  term: "term",
  AndRead: "and that you have read our",
  DataPolicy: "Data Policy and Cookie Use",
  CreateAccount: "Register",
  Agreement: "Agreement",
  Accept: "Accept",
  CertifiEmailSent: "Certifi Email Sent",
  EmailNotDelivered: "Email Not Delivered",
  DataNotFound: "No Data Found!",
  Subject: "Enter the subject",
  SMSDelivery: "SMS Delivery",
  EmailReadTime: "Email Read Time",
  Home: "Home",
  Payment: "Payment",
  Profile: "Profile",
  Setting: "Setting",
  Settings: "Settings",
  Email: "Email",
  SrNo: "Sr. No.",
  CertifiEmailCompose: "Certifi Email Compose",
  EnterDescription: "Enter Description",
  Send: "Send",
  AttachFile: "Attach a file",
  Save: "Save",
  BasicInfo:
    "Basic info, like your name and Email ID, that you entered at the time of account creation",
  Cancel: "Cancel",
  DraftEmail: "Draft Email",
  AggrementSignature: "Aggrement Signature",
  SignAgreement: "Sign Agreement",
  Decline: "Decline",
  WELCOME: "WELCOME",
  RegisterNewAccount: "Register new account",
  SubmitOTP: "Submit OTP",
  CertifiedDesc: "Please enter OTP to view certified mail",
  About: "About",
  Services: "Services",
  PrivacyPolicy: "Privacy Policy",
  HeroTitle: "Certified Communication Service",
  List1: "◉ Patented by the Government of India",
  List2: "◉ Authentic Proof of delivery and certified content",
  List3: "◉ Made In India",
  AboutCertify: "About CERTIFI",
  AboutCertifyContent:
    "Effective communication is vital for businesses and individuals	alike in today’s fast-paced digital world. With the growing reliance on electronic communication channels, ensuring the reliability and security of messages is crucial. This is where Certifi through Email and SMS comes into play. Certifi is provided under the brand name of Certifi by",
  ReadMore: "Read More",
  Certified_SMS_Heading: "Why Certified SMS & Email Communication Matter ?",
  Certified_SMS_Content1:
    "Certifi is an indispensable tool for ensuring reliable and secure messaging. Businesses and individuals can use these services to enhance communication reliably, increase message deliverability, and strengthen trust with their audience. It is crucial to carefully evaluate and choose a reputable platform that aligns with specific needs and requirements.",
  Certified_SMS_Card1_Heading: "SMS Digitally Signed Certificate",
  Certified_SMS_Card1_Content1:
    "Certified messages have higher chances of bypassing spam filters and reaching the recipient’s inbox.",
  Certified_SMS_Card2_Heading: "Email Digitally Signed Certificate",
  Certified_SMS_Card2_Content1:
    "Certification in mitigating the risk of phishing attacks and email spoofing.",
  Banks_and_Insurance: "Banks and Insurance companies",
  Banks_and_Insurance_Content: `Bill delivery, outstanding payment reminders, premium reminders, premium receipts, insurance related payments`,
  Courts: "Courts and other regulatory bodies",
  Courts_Content: `To send legal notices, orders, and other case related information etc.`,
  Government_Departments:
    "Government Departments like Tax department, Police, Municipalities",
  Government_Departments_Content: `To send important communications to citizens like Tax Notices, Traffic Challans, FIR copies, Municipal Tax, charges related communication`,
  General_Public: "General Public",
  General_Public_Content:
    "To send important documents such as contracts, wills, tender documents, KYC compliance documents etc.",
  Educational_Institutions: "Educational Institutions",
  Educational_Institutions_Content:
    "TFor exam notifications, roll numbers., result, degree, KYC",
  Enterprises: "Enterprises/ Companies",
  Enterprises_Content:
    "Various official and important communication with internal and external parties – HR related, Internal Communication, Vendor related, Sales and Customer related.",

  Footer_Logo_Content:
    "A patented, secure, and made-in-India solution for certified communication through email and SMS, empowering the masses through Digital India..",
  Company: "Company",
  Address: "Gurugram, Haryana, India.",
  QuickLinks: "Quick Links",
  Terms_Conditions: "Terms&Conditions",
  ContactUs: "Contact Us",
  UseCases: "Use Cases",
  AboutUs: "About Us",
  AboutUsContent: ` Synerthink delivers trusted data solutions for the connected
world. Co-founded by entrepreneurs with vast experience in the
global telecom ecosystem, communications are in the company’s DNA.
CERTIFI, a product of Synerthink, is an authentic and reliable
Digital Electronic Communication platform. As a new initiative of
Digital India, CERTIFI sets a benchmark for secure, efficient, and
eco-friendly communication. It ensures privacy and authenticity
through features like time stamping, content certification, and
message encryption, catering to both modern and traditional users
while aligning with the relevant provisions of the Laws of India
for proof of electronic communication.`,
  Vision: "Vision",
  VisionContent:
    "Our vision is to be the leading provider of Certified Communication Services globally, recognized for our commitment to service & product excellence, innovation, and customer satisfaction. We aim to continuously adapt to meet the evolving needs of our clients in an ever-changing digital landscape.",
  Mission: "Mission",
  MissionContent:
    "Our mission is to empower businesses and individuals with reliable and secure communication solutions through our Certified Communication Service. We strive to enhance their effectiveness.",

  Services: "Services",
  WhatMakesUsDifferent: "What makes us different?",
  CoreCommitment:
    "At our core, what sets us apart is our unwavering commitment to the reliability and security of our clients’ communications. We understand the critical importance of effective communication in today’s digital world, and we go above and beyond to ensure that every message is delivered safely and securely.",
  CommitmentToInnovation:
    "Our dedication to innovation enables us to stay ahead of emerging threats and challenges, providing our clients with peace of mind, knowing that their communication strategies are in capable hands.",
  CustomerCentricApproach:
    "Furthermore, our customer-centric approach means that we tailor our services to meet the unique needs and requirements of each client, offering personalized solutions and unparalleled support every step of the way.",
  Focus:
    "With our focus on reliability, security, innovation, and customer satisfaction, we are proud to be the trusted partner for businesses and individuals seeking to enhance their communication strategies with SMS and email certification services.",
  KeyFeatures: "Key Features",
  MessageAuthentication: "Message Authentication",
  MessageAuthentication_Content:
    "CERTIFI offers robust mechanisms to authenticate the message and ensures that messages remain untampered during transit. By leveraging these features, users can establish trust and prevent unauthorized access to sensitive information, ensuring secure and reliable communication.",
  IncreasedDeliverability: "Increased Deliverability",
  IncreasedDeliverability_Content:
    "CERTIFI enhances the deliverability of SMS and email messages by optimizing the routing process. This ensures that your messages reliably reach the intended recipient’s email address, maintaining effective communication.",
  SecurityMeasures: "Security Measures",
  SecurityMeasures_Content:
    "CERTIFI implements a range of security measures to protect your messages and sensitive data. This includes encrypting messages both in transit and at rest, securely storing user data, and adhering to industry-standard security protocols. These practices ensure robust protection against data breaches and unauthorized access, maintaining the confidentiality and integrity of your communications.",

  ScalabilityAndReliability: "Scalability and Reliability",
  ScalabilityAndReliability_Content:
    "A key feature of CERTIFI is its ability to efficiently and reliably handle large volumes of messages. CERTIFI provides robust infrastructure and redundant systems to ensure high availability and scalability, even during peak usage periods. This ensures that your communications remain seamless and uninterrupted, regardless of the volume of messages being processed.",
  SMSCertificate: "SMS Digitally Signed Certificate",
  SMSCertificate_Content:
    "Certified messages have higher chances of bypassing spam filters and reaching the recipients’ inboxes.",

  EmailCertificate: "Email Digitally Signed Certificate",
  EmailCertificate_Content:
    "Certification helps mitigate the risk of phishing attacks and email spoofing.",

    CERTIFI_EMAIL:'CERTIFI Email',

  REGISTER_AS_A_COSULTANT: 'Register as a consultant',
  Enter_Recipient_Email:`Enter Recipient's Email Address`,
  Enter_Recipient_Contact: `Enter Recipient's Contact Number`,
};
